export enum VoEStatus {
  NOT_STARTED = 'NOT_STARTED',
  REQUESTED = 'REQUESTED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum VoEType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum VoEAuditTrailType {
  CALL = 'CALL',
  EMAIL = 'EMAIL',
  FAX = 'FAX',
  NOTE = 'NOTE',
  ATS = 'ATS',
}
